<template>
  <div style="background: #eff3fc;   " :style="{'height':mainHeight}">
    <van-image :src="noInfo" style="width: 75%;margin-top: 60px; ">

    </van-image>

    <div style="margin-top: 30px;">
      <van-row>
        <van-col span="24"> 未建档，我要<span style="font-weight: bolder">【建档】</span></van-col>
      </van-row>
      <van-row>
        <van-col span="24"> 未绑卡，我要<span style="font-weight: bolder">【绑卡】</span></van-col>
      </van-row>
      <van-row>
        <van-col span="24"> 未预约挂号，我要<span style="font-weight: bolder" @click="goDepartment">【挂号】</span>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24"> 不知道看哪个科室，我要 <span style="font-weight: bolder" @click="goAi">【智能导诊】</span>
        </van-col>
      </van-row>

      <van-row>
        <van-col span="24"> 不方便到院问诊，我要居家问诊，</van-col>
      </van-row>
      <van-row>
        <van-col span="24"> 点击前往 <span style="color: #f60401;font-weight: bolder"
                                           @click="showTips">【互联网医院】</span></van-col>
      </van-row>
    </div>


  </div>
</template>


<script>
import {Row, Col, Toast} from 'vant';
import Vue from "vue";

Vue.use(Row).use(Col);
/*就医全流程*/
export default {
  name: "MTProcess",
  data() {
    return {
      noInfo: require('../../assets/无就诊.png')
    }
  },
  methods: {
    showTips() {
      Toast('暂未开通');
    },
    goAi() {
      this.$router.push({name: 'aiChat', query: {keyword: 'department'}})
    },
    goDepartment() {
      this.$router.push({name: 'department'})
    }
  },
  computed: {
    mainHeight: function () {
      let height = document.documentElement.clientHeight;//屏幕
      return height + 'px';
    },
  }
}
</script>

<style scoped>
.van-col--24 {
  line-height: 35px;
}
</style>