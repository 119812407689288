<template>
    <div>
        <div style="padding: 40px 20px;text-align: left;">
            <span style="float: left;font-size: medium">请如实填写您的个人信息，工作人员在收到资料后会尽快与您联系签约事宜。</span>
        </div>

        <van-divider style="margin: 10px 0 0 0"/>
        <van-field style="padding-top: 10px;" label="姓名" v-model="name" required placeholder="请输入姓名"
                   input-align="right"/>

        <van-field label="手机号码" placeholder="请输入手机号码" maxlength="11" v-model="phone" input-align="right"
                   required/>
        <van-field label="身份证号" placeholder="请输入身份证号" v-model="idNumber" input-align="right"/>
        <van-field name="radio" label="性别" input-align="right">
            <template #input>
                <van-radio-group v-model="radio" direction="horizontal">
                    <van-radio checked-color="#08a67c"  name="1" icon-size="16px">男</van-radio>
                    <van-radio checked-color="#08a67c"  name="2" icon-size="16px">女</van-radio>
                </van-radio-group>
            </template>
        </van-field>

        <van-cell title="出生日期" is-link @click="clickShowBirthday" :value="birthday"/>

        <van-popup v-model="showBirthday" position="bottom" :style="{ height: '30%',paddingBottom:'100px'}">
            <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    title="选择出生日期"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onConfirmBirthday"
                    @cancel="onCancelBirthday"
            />
        </van-popup>

        <van-popup v-model="showPopup" position="bottom" :style="{ height: '30%',paddingBottom:'100px'}">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"/>
        </van-popup>


        <van-field readonly required clickable label="现居地区" :value="valueArea" input-align="right"
                   placeholder="请选择现居地区"
                   @click="bindShow"/>

        <van-popup v-model="showArea" position="bottom" :style="{ height: '30%',paddingBottom:'100px'}">
            <van-area :area-list="areaList" @confirm="onAreaConfirm" @cancel="bindCancel"/>
        </van-popup>

        <van-field label="详细地址" placeholder="请输入详细地址" v-model="address" input-align="right"/>
        <div style="height: 5rem;"></div>
        <div class="submit-bar">
            <van-button round color="linear-gradient(to right, #09f6b7, #08a67c)"
                        style=" width: 200px; margin: 10px 20px;  " @click="addFamily">
                提交
            </van-button>
        </div>

    </div>
</template>

<script>

import Vue from 'vue';
import {Dialog, Field} from 'vant';
import {RadioGroup, Radio} from 'vant';
import {Cell, CellGroup} from 'vant';
import {Picker} from 'vant';
import {Popup} from 'vant';
import {Area} from 'vant';
import Areas from '@/utils/area'
import {DatetimePicker} from 'vant';
import {formatDate} from "@/utils/date";
import axios from "axios";

Vue.use(DatetimePicker);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
export default {
    name: "doctorForm",
    data() {
        return {
            areaList: Areas,
            idNumber: "",
            address: "",
            phone: "",
            name: "",
            birthday: "",
            showPopup: false,
            showArea: false,
            showBirthday: false,
            radio: '1',
            currentDate: new Date(),
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            valueArea: '', //地区值
            arrArea: [], //存放地区数组
            province: '',
            city: '',
            area: ''
        };
    },
    mounted() {
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");
    },
    methods: {
        //新增订单
        addFamily() {
            if (this.name.length === 0) {
                Dialog.alert({message: '请输入姓名'});
                return;
            }
            if (this.phone.length === 0) {
                Dialog.alert({message: '请输入手机号码'});
                return;
            }
            if (this.phone.length !== 11) {
                Dialog.alert({message: '手机号输入有误'});
                return;
            }
            if (this.province.length === 0) {
                Dialog.alert({message: '请选择现居地区'});
                return;
            }
            let that = this;
            //todo let appid = localStorage.getItem('appid');
          //todo let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
          //todo axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/orders/doctor', {
                "name": this.name,
                "companyId": localStorage.getItem('companyId'), //todo userInfo.companyId,
                "userId": localStorage.getItem('userId'), //todo userInfo.id,
                "gender": this.radio,
                "birthday": this.birthday,
                "identityCard": this.idNumber,
                "provinceId": this.province,
                "cityId": this.city,
                "regionId": this.area,
                "address": this.address,
                "phone": this.phone,
                'modelId': localStorage.getItem('chooseDoctorId')
            })
                .then(function (response) {
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on close
                        that.$router.back();
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onConfirm(value) {
            this.relation = value;
            this.showPopup = false;
        },
        onCancel() {
            this.showPopup = false;
        },
        clickShowBirthday() {
            this.showBirthday = true;
        },
        onConfirmBirthday(value) {
            // console.log(console.log( formatDate(value, 'yyyy-MM-dd')));
            this.birthday = formatDate(value, 'yyyy-MM-dd');
            this.showBirthday = false;
        },
        onCancelBirthday() {
            this.showBirthday = false;
        },
        clickShowPicker() {
            this.showPopup = true;
        },
        bindShow() {
            this.showArea = true;
        },
        bindCancel() {
            this.showArea = false;
        },
        //地区选择
        onAreaConfirm(val) {
            this.showArea = false;
            this.arrArea = val;
            this.valueArea = val[0].name + '-' + val[1].name + '-' + val[2].name;
            this.province = val[0].code;
            this.city = val[1].code;
            this.area = val[2].code;
        },

    }
}
</script>

<style scoped>
.van-collapse-item {
    text-align: left;
}

.van-cell {
    line-height: 34px;
}

.van-cell__title {
    text-align: left;
}

.van-cell__left-icon, .van-cell__right-icon {
    line-height: 34px;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}
.van-radio__icon--checked .van-icon {
  background-color: #08a67c;
  border-color: #08a67c;
}
.van-radio__icon--checked  {
  background-color: #08a67c;
  border-color: #08a67c;
}

</style>