<template>
  <div style="background: #eff3fc;   " :style="{'height':mainHeight}">
    <van-image :src="noInfo" style="width: 75%;margin-top: 60px; ">

    </van-image>

    <div style="margin-top: 30px;">
      <van-row>
        <van-col span="24"> 当前暂未门诊叫号 </van-col>
      </van-row>

    </div>


  </div>
</template>


<script>
import {Row, Col, Toast} from 'vant';
import Vue from "vue";

Vue.use(Row).use(Col);
/*排队查询*/
export default {
  name: "lineup",
  data() {
    return {
      noInfo: require('../../assets/无信息.png')
    }
  },
  methods: {
    showTips() {
      Toast('暂未开通');
    },
    goAi() {
      this.$router.push({name: 'aiChat', query: {keyword: 'department'}})
    },
    goDepartment() {
      this.$router.push({name: 'department'})
    }
  },
  computed: {
    mainHeight: function () {
      let height = document.documentElement.clientHeight;//屏幕
      return height + 'px';
    },
  }
}
</script>

<style scoped>
.van-col--24 {
  line-height: 35px;
}
</style>