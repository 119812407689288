<template>
  <div>

    <van-tabs v-model="activeTab" animated swipeable color='#08a67c'>
      <van-tab title="添加血压">
        <van-cell-group>
          <van-field v-model="dateTime" label="测量时间" @click="visible=true">
          </van-field>
          <vue-hash-calendar format="YY-MM-DD hh:mm" :visible="visible" model="dialog"
                             @confirm="confirmDate"/>
          <van-field label="高压" v-model="high" type="number" placeholder="40-300mmHg"/>
          <van-field label="低压" v-model="low" type="number" input-align="left" placeholder="20-280mmHg"/>
          <van-field label="心率" v-model="rate" type="number" input-align="left" placeholder="30-200BPM"/>
          <van-field label="备注" v-model="mark1" input-align="left" placeholder="今天没吃饭"/>
        </van-cell-group>

        <van-button round style="margin-top: 50px;width: 70%;text-align: center;" type="primary" @click="savePressure">保存</van-button>

      </van-tab>
      <van-tab title="添加血糖">
        <van-cell-group>
          <van-field v-model="dateTime" label="测量时间" @click="visible=true">
          </van-field>
          <vue-hash-calendar format="YY-MM-DD hh:mm" :visible="visible" model="dialog"
                             @confirm="confirmDate"/>
          <van-field label="血糖" type="number" v-model="sugar" placeholder="3.0-20.0mmol/L"/>
          <van-field label="类型" v-model="type" input-align="left" @focus="showSheet=true"/>
          <van-field label="备注" v-model="mark2" input-align="left" placeholder="请输入备注"/>
        </van-cell-group>

        <van-button round style="margin-top: 50px;width: 70%;text-align: center;" type="primary" @click="saveSugar">保存</van-button>

      </van-tab>

      <van-tab to="upload" title="上传处方">上传处方</van-tab>
    </van-tabs>

    <van-action-sheet
        v-model="showSheet"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @cancel="onCancel"
        @select="onSelect"
    />
  </div>

</template>
<script>
import axios from "axios";
import {Dialog} from "vant";

export default {
  name: "addBloodPressure",
  data() {
    return {
      showSheet: false,
      actions: [{name: '空腹'}, {name: '饭后2小时'}, {name: '未知'}],
      high: '',
      low: '',
      rate: '',
      mark1: '',
      sugar: '',
      type: '空腹',
      mark2: '',
      activeTab: 0,
      visible: false,
      dateTime: '',
      userInfo: [],
    };
  },
  mounted() {
    document.title = "添加血压数据"
    const date = new Date();
    this.dateTime = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

    this.activeTab = this.$route.query.index;

    this.userInfo = JSON.parse(localStorage.getItem("userInfo" + localStorage.getItem('appid')));
  },
  methods: {
    confirmDate(value) {
      this.visible = false;
      this.dateTime = value;
    },
    onSelect(item) {

      this.type = item.name;
    },
    showActionSheet() {
      this.showSheet = true;
    },
    onCancel() {

    },
    savePressure() {
      if(this.high === '' || this.low === ''  ) {
        Dialog.alert({
          message: '请输入高压值和低压值',
          messageAlign: 'left',
          confirmButtonColor: 'black'
        })
        return;
      }

      let that = this;
      /*let appid = localStorage.getItem('appid');
      let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
      axios.defaults.headers.common.Authorization = localStorage.getItem("token");*/
      axios.post('/chronic/save', {
        "userId": this.userInfo.id,
        "companyId": this.userInfo.companyId,
        "type": 1,
        "value1": this.high,
        "value2": this.low,
        "value3": this.rate,
        "dateTime": this.dateTime,
        "mark": this.mark1,
      })
          .then(function (response) {
            console.log('添加家庭成员')
            console.log(JSON.stringify(response.data))
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
              confirmButtonColor: 'black'
            }).then(() => {
              // on close
              //if (response.data.code === 200)
              that.$router.back()
            });
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    saveSugar() {
      if(this.sugar === '') {
        Dialog.alert({
          message: '请输入血糖值',
          messageAlign: 'left',
          confirmButtonColor: 'black'
        })
        return;
      }

      let that = this;
      /*let appid = localStorage.getItem('appid');
      let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
      axios.defaults.headers.common.Authorization = localStorage.getItem("token");*/
      axios.post('/chronic/save', {
        "userId": this.userInfo.id,
        "companyId": this.userInfo.companyId,
        "type": 2,
        "value1": this.sugar,
        "value2": this.type,
        "value3": '',
        "dateTime": this.dateTime,
        "mark": this.mark1,
      })
          .then(function (response) {
            console.log('添加家庭成员')
            console.log(JSON.stringify(response.data))
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
              confirmButtonColor: 'black'
            }).then(() => {
              // on close
              //if (response.data.code === 200)
              that.$router.back()
            });
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  }
}
</script>
<style scoped>

</style>