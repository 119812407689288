import axios from "axios";
import wx from "weixin-js-sdk";

export default {
    getAppid() {
        let url = window.location.href;             //获取当前url
        let dz_url = (url || '').split('#')[0];                //获取#/之前的字符串
        let cs = (dz_url || '').split('?')[1];                //获取?之后的参数字符串
        let cs_arr = (cs || '').split('&');                    //参数字符串分割为数组
        let res = {};
        for (let i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
            res[(cs_arr[i] || '').split('=')[0]] = (cs_arr[i] || '').split('=')[1]
        }
        return res.appid;
    },
    initJsSDK() {
        alert("initJsSDK")
        axios.post('/wechat/jsSdk', {
            url: location.href.split('#')[0],
            appId: localStorage.getItem('appid')
        })
            .then(function (response) {
                let res = response.data.data;
                wx.config({
                    debug: false,
                    appId: res.appId,
                    timestamp: res.timestamp,
                    nonceStr: res.nonceStr,
                    signature: res.signature,
                    jsApiList: ['getLocation', 'openLocation', 'updateAppMessageShareData', 'onMenuShareAppMessage', 'scanQRCode', 'chooseWXPay']
                });
                wx.error(function (res) {
                    console.log('wx.config.error')
                    console.log(res)
                });
                wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                    //const companyInfo = JSON.parse(localStorage.getItem("companyInfo")) ;
                    wx.onMenuShareAppMessage({
                        title: localStorage.getItem("companyName"), // 分享标题
                        desc: localStorage.getItem("companyName"), // 分享描述
                        link: 'http://p.eshekang.com/#/index?appid='+ localStorage.getItem("appid"), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'http://vaccin-image.wxdev.top/logo.png', // 分享图标
                        success: function () {
                            // 设置成功
                            //that.getLocation();
                        }, fail: function (res) {
                            console.log(res)
                            //alert("initJsSDK初始化失败" + JSON.stringify(res))
                        }
                    })
                });
            })
            .catch(function (error) {
                console.log(error);
                //alert(JSON.stringify(error))
            });
    },
    openMap(clinicInfo) {
        wx.openLocation({
            latitude: parseFloat(clinicInfo.latitude),
            longitude: parseFloat(clinicInfo.longitude),
            name: clinicInfo.title,
            address: clinicInfo.address,
            scale: 28,
            infoUrl: ''
        });
    },
    getCode(appid) {
        //todo
        console.log(appid)
        //let url = encodeURIComponent(location.href.split('?')[0]);
        //window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + url + '&response_type=code&scope=snsapi_userinfo&state=STATE&component_appid=wx27058bb1a89fb74d#wechat_redirect';
    },
    getQueryVariable(variable) {
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return false;
    },
    getUserInfo() {
        let code = this.getQueryVariable('code');
        let appid = this.getQueryVariable('appid');
        //alert('code = ' + code)
        let that = this;
        //请求文章列表
        axios.post('/wechat/user', {
            "appId": appid,
            "code": code
        })
            .then(function (response) {
                //alert(JSON.stringify(response.data.data))
                localStorage.setItem('userInfo' + appid, JSON.stringify(response.data.data))
                localStorage.setItem('companyId', JSON.stringify(response.data.data.companyId))
                localStorage.setItem('userId', JSON.stringify(response.data.data.id))
                that.initConfig = true;
                that.initJsSDK();
                that.getClinicInfo();
            })
            .catch(function (error) {
                alert(error.message)
            });

    },
    //机构信息
    getClinicInfo() {
        axios.post('/companies/info', {
                id: localStorage.getItem("companyId")
            }
        )
            .then(function (response) {
                localStorage.setItem('companyName', response.data.data.title)
                document.title = response.data.data.title;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    //获取公司信息
    getCompanyInfo(appid) {
        axios.post('/companies/appId', {
            "appId": appid
        })
            .then(function (response) {
                localStorage.setItem('companyId', JSON.stringify(response.data.data.id))
                localStorage.setItem('companyName', response.data.data.title)
                localStorage.setItem('companyInfo', response.data.data)
                document.title = response.data.data.title;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getCity() {
        const jsonp = require('jsonp');
        let ajaxUrl = 'https://restapi.amap.com/v3/geocode/regeo?key=4b4f06c21b63eceb8a733bc5350620ed&s=rsv3&language=zh_cn&location=' + location + '&radius=1000&platform=JS&logversion=2.0&sdkversion=1.4.15'
        let that = this;
        jsonp(ajaxUrl, null, (err, data) => {
            if (err) {
                console.error(err.message);
            } else {
                console.log(data);
                //let json = JSON.parse(data)
                that.curCity = data.regeocode.addressComponent.city;
                let curCityId = data.regeocode.addressComponent.adcode;
                //alert(data.regeocode.addressComponent.city)
                localStorage.setItem('locationCity', that.curCity)
                localStorage.setItem('locationCityId', curCityId)
                localStorage.setItem('chooseCityName', that.curCity)
                localStorage.setItem('chooseCityId', curCityId);
            }
        });
    },
    getLocation() {
        let that = this;
        wx.getLocation({
            type: 'gcj02',
            success: function (res) {
                let latitude = res.latitude;
                let longitude = res.longitude;
                //let speed = res.speed;
                //let accuracy = res.accuracy;

                //存入localStorage
                localStorage.setItem('latitude', latitude);
                localStorage.setItem('longitude', longitude);


                //自动进入最近的机构
                //that.nearClinic('getToken');
                //alert('latitude = ' + latitude + ' longitude = ' + longitude )
                that.getCity(longitude + ',' + latitude);
            }
        });
    }

}