<template>
  <!--  <van-sidebar v-model="active" @change="onChange">
      <span v-for="(item, index) in parentList" :key="index">
        <van-sidebar-item :title=item.title />
      </span>

    </van-sidebar>-->
  <div style="">
    <div id="app">
      <draggable-ball></draggable-ball>
    </div>
    <div style="padding: 30px 20px;text-align: left;border-bottom: #eeeeee solid;">
      <span style="float: left;font-size: large;line-height: 10px;">诊疗须知</span>
    </div>
    <!--    <van-tree-select height="200vw" :items="items" :active-id.sync="activeId" :main-active-index.sync="active">
          <template #content>

          <span v-for="(item, index) in departmentList" :key="index">
            <van-cell is-link @click="doctor(item.deptId, item.title)">
              <template #title style="text-align: start;">
                <span class="custom-title">{{ item.title }}</span>
              </template>
            </van-cell>
          </span>

          </template>

        </van-tree-select>-->
    <div style="margin: 20px; padding: 20px;background: rgb(252,254,255);border-radius: 15px;">


      <p class="p-content">分时段预约：患者可根据自己的时间来选择号源，避免排队等候。</p>

  <p class="p-content">取号：就诊当天，完成诊察费用支付后无需取号，直接去诊区按序号就诊即可；如您是通过电话预约，需要在预约时间前携带患者本人有效身份证件到医院任一挂号缴费窗口进行付费取号，超过预约就诊时间自动作废。</p>

  <p class="p-content">退号：如患者因故无法准时到医院就诊，请在就诊前一天下午23点之前按原预约方式取消，诊察费用原路返回。就诊当日不可取消预约，如需退号要携患者本人身份证件到一层大厅自助机上打印挂号凭证后，到现场任一收费窗口办理退费。</p>

  <p class="p-content">停诊：若患者已预约的专家因故停诊，系统将自动取消该预约并短信通知患者，如您是使用医保缴费的，通过原渠道进入缴费单详情页面点击退费处理，支付宝用户打开支付宝医院小程序进入缴费单详情，腾讯医保用户打开医院公众号进入缴费单详情点异常单退费，如未退号，请于就诊时间段前至院内人工窗口申请退号。</p>

  <p class="p-content">预约限制：同一患者在同一医院同一科室每日仅限进行一次预约操作。</p>

  <p class="p-content">黑名单规则：累及爽约三次或连续无故取消预约十次将列入黑名单，此后90天内不能在线上享受预约挂号服务。</p>

  <p class="p-content">医院咨询电话：0757-88888888（工作时间：上午 8：00--12：00 下午 14：30（冬）/15:00（夏）--18：00）</p>
     </div>
  </div>

</template>

<script>

import {TreeSelect} from 'vant';
import Vue from "vue";
import comFun from '../../utils/jssdk';

Vue.prototype.comFun = comFun;

Vue.use(TreeSelect);
export default {
  name: "advisory",
  components: {},
  data() {
    return {
      // 左侧高亮元素的index
      activeIndex: '',
      mainActiveIndex: '',
      // 被选中元素的id
      before: 'van-icon van-icon-arrow van-cell__right-icon',
      activeId: '',
      active: '',
      parentList: [],
      departmentList: [],
      items: [],
    }
  },
  created() {

  },
  mounted() {
    // this.getParentList();
  },
  methods: {},
  watch: {
    activeId(newValue, oldValue) {
      alert('oldValue = ' + oldValue + ' newValue = ' + newValue)


    },
    active(newValue, oldValue) {
      //alert('oldValue = ' + oldValue + ' newValue = ' +newValue)
      console.log(newValue)
      console.log(oldValue)
      this.getDepartmentList(this.parentList[newValue].deptId)
    }
  }
  /*setup() {
    const active = ref(0);
    const onChange = (index) => Toast(`标签名 ${index + 1}`);
    return {
      active,
      onChange,
    };
  },*/
}
</script>

<style scoped>
.van-cell__title {
  font-size: medium;
}

.p-content {
  text-align: start;
}
</style>