import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import axios from 'axios'
import VueWechatTitle from 'vue-wechat-title'
//import "./common/font/font.css"
Vue.use(VueWechatTitle)
//Vue.prototype.$axios = axios
axios.defaults.baseURL = 'http://wx.wxdev.top:8088';
//axios.defaults.baseURL = 'http://127.0.0.1:8088';
//axios.defaults.baseURL = 'https://api.eshekang.com';
axios.defaults.headers.common.Authorization = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZCIsImlzcyI6ImdkIiwiZXhwIjoxNjU4NzM5NzIxLCJpYXQiOjE2NTc4NzU3MjEsInJvbCI6IjEifQ.nM1kd5u8PGNFu75cwLkMY2cO1_4P020UXxpsQOa33f--mli_0ajcRq2PIYwDVYx872p3XFHmvrk9KTX5bjozqg';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.timeout = 35000;//35秒超时
Vue.config.productionTip = false
Vue.platformTitle = '';
// 在入口文件中（main.js），导入组件库
import vueHashCalendar from 'vue-hash-calendar'
// 引入组件CSS样式
import 'vue-hash-calendar/lib/vue-hash-calendar.css'

// 注册组件库
Vue.use(vueHashCalendar)
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

