<template>
  <div>
    <div style="padding: 30px 20px;text-align: left;border-bottom: #eeeeee solid;">
      <span style="float: left;font-size: large;line-height: 10px;">{{ department }}(全部医生)</span>
    </div>
    <vue-hash-calendar :markDate="markDate" @change="selectDate" :isShowWeekView="isShowWeekView" :pickerType="date"
                       :visible="true" :disabledScroll="true" :weekStart="weekStart"/>

    <span v-for="(item, index) in departmentList" :key="index" @click="goDoctorDetail(item);">
              <van-divider/>
              <div style=" float: left; height: 100px; width: 30%; text-align:center;">
                    <van-image
                        cover
                        radius="6px"
                        width="80px"
                        height="80px"
                        src="https://vaccin-image.wxdev.top/yisheng-2.png"
                    />
              </div>
              <div style="width: 100%;">
                <div style="height: 100px;margin: 15px; ">
                  <div style="width: 100%; ">
                    <div style="text-align: left;float: left;">
                      <div class="van-multi-ellipsis--l2" style="font-size: medium;font-weight: 550;color: #565656;">
                        {{ item.DoctorName }}
                      </div>
                    </div>

                    <div style="text-align: right;float: right;">

                      <div class="van-multi-ellipsis--l2" style="font-size: small;font-weight: 550;color: #565656;">
                        <span v-show="showRemainder"> 余号:{{ item.AvailableCount }} </span><van-tag
                          type="warning">￥{{ item.Price }}</van-tag>
                      </div>
                    </div>

                    <div style="text-align: left;float: left;margin-top: 10px;width: 70%;">
                      <span style="color: coral;" class="goodAt">
                         {{ item.subtitle }}
                      </span>
                    </div>

                    <div style="text-align: left;float: left;width: 70%; ">
                      <span class="goodAt" style="font-size: small;">
                         {{ item.goodAt }}
                      </span>
                    </div>
                    </div>
                </div>
            </div>
    </span>

    <van-empty v-if="departmentList.length === 0" :description="description">
    </van-empty>

  </div>
</template>

<script>
import Vue from 'vue';
import {Card} from 'vant';
import axios from "axios";
import {Tag} from 'vant';

Vue.use(Tag);
Vue.use(Card);
export default {
  name: "Doctor",
  data() {
    return {
      //minDate:new Date(),
      description: '正在查询,请稍后....',
      markDate: [],
      isShowWeekView: true,
      calendarVisible: false,
      date: 'date',
      weekStart: '',
      showRemainder: true,
      regDate: '',
      defaultDate: '',
      department: [],
      departmentList: [],
      show: true,
      minDate: new Date(2023, 2, 25),
      maxDate: new Date(2023, 3, 10),
      days: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    };
  },
  mounted() {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    this.regDate = this.formatDate(date);//默认选中第二天

    this.minDate = new Date(date.setDate(date.getDate()));
    this.maxDate = new Date(date.setDate(date.getDate() + 14));
    this.department = localStorage.getItem('departmentTitle');

    //AI推荐过来的部门名称带在URL上
    let depart = this.$route.query.depart;
    if (depart != undefined && depart != null && depart.length > 1) {
      this.department = depart;
    }

    //this.getDoctorList();


  },
  created() {
    // 默认今天在第一天
    const date = new Date();
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    this.weekStart = days[date.getDay()];
  },
  methods: {
    selectDate(date) {
      // alert(this.formatDate(date))
      this.showRemainder = true;
      this.regDate = this.formatDate(date);
      this.getDoctorList();
    },
    goDoctorDetail(item) {
      this.$router.push({
        name: 'doctorDetail',
        params: {
          index: item.id
        },
        query: {
          id: item.id,
          DoctorName: item.DoctorName,
          DoctorId: item.DoctorId,
          deptId: this.$route.query.index,
        }
      });
    },
    getDoctorList() {
      let that = this;
      that.description = '正在查询,请稍候....'
      that.departmentList.length = 0;
      let appid = localStorage.getItem('appid');
      let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
      axios.post('/doctor/schedule', {
        "modelType": "App\\Queues",
        "userId": userInfo.id,
        "state": 1,
        "title": "_",
        "deptId": this.$route.query.index,
        "page": 1,
        "date": this.regDate,
        "per_page": 20,
        "companyId": userInfo.companyId
      })
          .then(function (response) {
            that.departmentList = response.data.data.list;
            if (response.data.data.list.length === 0) {
              that.description = '未查询到号源信息'
            } else {
              //标记出有号的日期避免反复刷
              that.markDate.push(that.regDate)
            }
            localStorage.setItem("record", JSON.stringify(response.data.data.record))
          })
          .catch(function (error) {
            console.log(error);
            that.description = '未查询到号源信息'
          });
    },
    //将num左补0为len长度的字符串
    lpadNum(num, len) {
      let l = num.toString().length;
      while (l < len) {
        num = "0" + num;
        l++;
      }
      return num;
    },
    //将传入的Date格式化为"yyyyMMdd HH:mm:ss.SSS"
    formatDate(d) {
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let day = d.getDate();

      return year + '-' + this.lpadNum(month, 2) + '-' + this.lpadNum(day, 2);
    }
  }
}
</script>

<style scoped>
.van-card {
  text-align: left;
}

.goodAt {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>