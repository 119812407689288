<template>
  <div :style="{'height':swipeHeight}" style="background: #f7f8fd;width: 100%;">
    <div style="height: 100px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">
      <van-image
          style="margin-left: 30px;margin-top: 15px;float: left;margin-right: 20px;"
          round
          width="60px"
          height="60px"
          :src=userInfo.avatar
      />

      <div style="line-height: 100px;padding-left: 20px;font-size: medium;font-weight: 550;">
        {{ userInfo.name }}
      </div>
    </div>

    <div style="margin: 15px;border-radius: 10px;background: white;">
      <van-grid :border="false" :column-num="3">
        <van-grid-item to="/doctorOrder">
          <van-image width="30%" src="http://vaccin-image.wxdev.top/doctorOrder.png"/>
          <span class="van-grid-item-text">签约记录</span>
        </van-grid-item>
        <van-grid-item to="/insuranceList">
          <van-image width="30%" src="http://vaccin-image.wxdev.top/insurances3.png"/>
          <span class="van-grid-item-text">医保定点</span>
        </van-grid-item>
        <van-grid-item @click="goReserve">
          <van-image width="30%" src="http://vaccin-image.wxdev.top/goReserve2.png"/>
          <span class="van-grid-item-text">我的预约</span>
        </van-grid-item>
      </van-grid>
    </div>

    <van-sticky :offset-top="40">
      <div
          style="text-align: left;margin-left: 15px;font-weight: 550;font-size: medium;padding-top: 10px;align-items: center;line-height: 30px;">
        我的服务
      </div>
    </van-sticky>

    <div style="margin: 15px;border-radius: 10px;background: white;">
      <van-cell is-link icon="http://vaccin-image.wxdev.top/scan.png" style="color: #00eeee;" to="scan">
        <template #title style="text-align: start;">
          <span class="custom-title">扫码确认</span>
        </template>
      </van-cell>
      <van-cell is-link icon="http://vaccin-image.wxdev.top/guahao2.png" style="color: #ee0a24;"
                to="registrationList">
        <template #title style="text-align: start;">
          <span class="custom-title">挂号记录</span>
        </template>
      </van-cell>
      <van-cell is-link icon="http://vaccin-image.wxdev.top/subscribe.png" style="color: #ee0a24;"
                to="subscribeList">
        <template #title style="text-align: start;">
          <span class="custom-title">我的订阅</span>
        </template>
      </van-cell>
      <van-cell is-link icon="http://vaccin-image.wxdev.top/queues.png" style="color: #ee0a24;" to="queueList">
        <template #title style="text-align: start;">
          <span class="custom-title">排队订单</span>
        </template>
      </van-cell>

      <!--      <van-cell is-link icon="hotel-o" style="color: #1989fa;"  to="collection">
              <template #title style="text-align: start;">
                <span class="custom-title" >收藏机构</span>
              </template>
            </van-cell>-->

      <van-cell is-link icon="http://vaccin-image.wxdev.top/service.png" style="color: #7bd9f5;" >
        <template #title style="text-align: start;">
          <span class="custom-title" @click="cleanCache">联系客服</span>
        </template>
      </van-cell>


    </div>

    <div style="margin: 15px;border-radius: 10px;background: white;">
      <van-cell is-link icon="http://vaccin-image.wxdev.top/Personal%20Info.png" style="color: #44caf2;"
                to="setting">
        <template #title style="text-align: start;">
          <span class="custom-title">个人信息</span>
        </template>
      </van-cell>
    </div>
    <div style="height: 5rem;"></div>

    <van-tabbar route v-model="active">
      <van-tabbar-item replace to="/index" icon="wap-home">
        诊疗服务
      </van-tabbar-item>
      <!--<van-tabbar-item replace to="/news" icon="cart-o">
        在线商城
      </van-tabbar-item>-->
      <van-tabbar-item replace to="/user" icon="friends">
        个人中心
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from 'vue';
import {Cell, CellGroup} from 'vant';
// import axios from "axios";
import comFun from '../../utils/jssdk';
import axios from "axios";

Vue.prototype.comFun = comFun;

Vue.use(Cell);
Vue.use(CellGroup);
export default {
  name: "user",
  data() {
    return {
      active: 'user',
      userInfo: []
    };
  },
  created() {
    let appid = this.comFun.getAppid();
    if (appid === undefined) {
      appid = this.$route.query.appid;
    }
    if (appid !== undefined) {
      localStorage.setItem('appid', appid);
      this.comFun.getCompanyInfo(appid);
    } else {
      appid = localStorage.getItem('appid');
      this.comFun.getCompanyInfo(appid);
    }

    let code = this.comFun.getQueryVariable('code');
    if (localStorage.getItem('userInfo' + appid) == null) {
      if (code === false || code.length < 10) {
        const appid2 = this.$route.query.appid;
        if (localStorage.getItem('appid') === undefined) {
          localStorage.setItem('appid' + appid2, appid2);
        }
        this.comFun.getCode(appid2);
      } else {
        this.getUserInfo();
      }
    } else {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
      document.title = localStorage.getItem("companyName");
      this.comFun.initJsSDK();
    }
  },
  mounted() {

    // axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
    // this.getUserInfo();
  },
  methods: {
    //机构信息
    getClinicInfo() {
      let appid = localStorage.getItem('appid');
      let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
      axios.post('/companies/info', {
            id: userInfo.companyId
          }
      )
          .then(function (response) {
            localStorage.setItem('companyName', response.data.data.title)
            document.title = response.data.data.title;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getUserInfo() {
      let code = this.comFun.getQueryVariable('code');
      let appid = this.comFun.getQueryVariable('appid');
      let that = this;
      //请求文章列表
      axios.post('/wechat/user', {
        "appId": appid,
        "code": code
      })
          .then(function (response) {
            that.userInfo = response.data.data;
            localStorage.setItem('userInfo' + appid, JSON.stringify(response.data.data))
            localStorage.setItem('companyId', JSON.stringify(response.data.data.companyId))
            localStorage.setItem('userId', JSON.stringify(response.data.data.id))

            that.initConfig = true;
            that.comFun.initJsSDK();
            that.getClinicInfo();
          })
          .catch(function (error) {
            alert(error.message)
          });

    },
    cleanCache() {
      //Dialog.alert('客服微信号复制成功，请添加客服微信');

      //localStorage.setItem('userInfo' + appid, null);
      //localStorage.removeItem('userInfo');

      //this.$router.push('payment')
      this.$router.push({name: 'AiChat', query: {keyword: this.$route.name}})
    },
    goReserve() {
      this.$router.push('reserveList')
    },
    subscribeList() {
      this.$router.push('SubscribeList')
    },
    queueList() {
      this.$router.push('queueList')
    }
  },
  computed: {
    swipeHeight: function () {
      let height = document.documentElement.clientHeight;//屏幕高

      return height + 'px';
    }
  }
}
</script>

<style scoped>
.van-cell__title, .van-cell__value {
  text-align: start;
}

.custom-title {
  color: #565656;
  font-size: medium;
}

.van-cell__left-icon, .van-cell__right-icon {
  font-size: 19px;
}

.van-grid-item-text {
  font-size: 0.87rem;
  color: #555555;
  white-space: nowrap;
  font-weight: 500;
}
.van-tabbar-item--active {
  color:#08a67c;
}
</style>