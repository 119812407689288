<template>
    <div>
        <div style="height: 70px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">

            <div style="line-height: 70px;padding-left: 20px;font-size: medium;font-weight: 550;">
                医保列表
            </div>
        </div>
        <span v-for="(item, index) in reverseList" :key="index">
        <div style="height: 220px;background: white;border-radius: 10px;margin:15px; box-shadow:6px 6px 9px #eee;"
             @click="information(item)">

        <div style="padding: 20px;">
          <span style="float: left;font-size: large;">{{ item.companyName }}</span>
         </div>
        <van-divider/>
        <div style="height: 25px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: medium">姓名</span>
          <span style="text-align: right;float: right; font-size: medium;color: #565656;">
            {{ item.name }}</span>
        </div>
        <div style="height: 25px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: medium">电话</span>
          <span style="text-align: right;float: right; font-size: medium;color: #565656;">
            {{ item.phone }}</span>
        </div>
        <div style="height: 25px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: medium">医保卡号</span>
          <span style="text-align: right;float: right; font-size: medium;color: #565656;">
            {{ item.cardNumber }}</span>
        </div>
        <div style="height: 25px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: medium">创建时间</span>
          <span style="text-align: right;float: right; font-size: medium;color: #565656;">
            {{ item.createdAt }}</span>
        </div>

      <div style="height: 25px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: medium">状态</span>
          <span v-if="item.state === '0'" style="text-align: right;float: right; font-size: medium;color: darkorange;">
            待处理</span>
          <span v-else-if="item.state === '1'" style="text-align: right;float: right; font-size: medium;color: green;">
            已绑定</span>
          <span v-else-if="item.state === '-1'"
                style="text-align: right;float: right; font-size: medium;color: orangered;">
            绑卡失败</span>
        </div>

        </div>
             
    </span>
        <van-empty v-if="reverseList.length === 0" description="暂无数据">
        </van-empty>
        <div>
            <van-button round to="insurance" type="info" style="height: 36px;">+申请医保定点</van-button>
        </div>
        <div style="height: 5rem;"></div>
    </div>
</template>

<script>
import Vue from 'vue';
import {Dialog, Panel} from 'vant';
import {Cell, CellGroup} from 'vant';
import axios from "axios";
/*import comFun from '../../utils/jssdk';
Vue.use(comFun);*/
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Panel);
export default {
    name: "insuranceList",
    data() {
        return {
            reverseList: [],
            injectionNumber: [
                {value: 1, text: "第一针"}, {value: 2, text: "第二针"}, {value: 3, text: "第三针"}, {
                    value: 4,
                    text: "第四针"
                }, {value: 5, text: "第五针"},
                {value: 6, text: "第六针"}, {value: 7, text: "第七针"}, {value: 8, text: "第八针"}, {
                    value: 9,
                    text: "第九针"
                }, {value: 10, text: "第十针"},
                {value: 11, text: "第十一针"}, {value: 12, text: "第十二针"}, {value: 13, text: "第十三针"}, {
                    value: 14,
                    text: "第十四针"
                }, {value: 15, text: "第十五针"}
            ],
        };
    },
    beforeCreate() {
        //进入页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #f7f8fd');
    },
    beforeDestroy() {
        //退出页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #fff');
    },
    mounted() {
        /*if(localStorage.getItem("token") == null) {
          this.getUserInfo();
          this.getLocation();
        }*/
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");
        this.getReverseList();
    },
    methods: {
        getState(state) {
            var str = '待处理';
            switch (state) {
                case 0:
                    str = '待处理';
                    break;
                case 1:
                    str = '已绑定';
                    break;
            }
            return str;
        },
        information(item) {
            if (item.state === '0')
                Dialog.alert({message: '请等待工作人员处理', messageAlign: 'left'});
            else if (item.state === '1')
                Dialog.alert({message: '已完成绑定', messageAlign: 'left'});
            else if (item.state === '-1')
                Dialog.alert({message: item.remark, messageAlign: 'left'});

        },
        //针次
        injection(inject) {
            let status;
            this.injectionNumber.forEach(function (element) {
                console.log('element = ' + JSON.stringify(element))
                if (inject === element.value) {
                    status = element.text
                }
            });

            return status;
        },
        goReverseDetail(item) {
            this.$router.push({name: 'reserveDetail', query: {id: item.id}})
        },
        getReverseList() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/insurances/list', {
                userId: userInfo.id
            })
                .then(function (response) {
                    that.reverseList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>
.van-button--info {
  background-color: #08a67c;
  border:1px solid #08a67c;
}
</style>