<template>
  <div>
    您好！我是AI智能客服。
    您有任何问题都可以问我
    <van-divider />
    您可以像这样提问

    <van-tag plain type="success" @click="alert1">标签</van-tag>
    <van-tag plain type="success">标签</van-tag>
    <van-tag plain type="success">标签</van-tag>
    <van-tag plain type="success">标签</van-tag>
    <van-tag plain type="success">标签</van-tag>
    <van-tag plain type="success">标签</van-tag>
    <van-tag plain type="success">标签</van-tag>
  </div>
</template>

<script setup>
import Vue from 'vue';
import { Divider } from 'vant';
import { Tag } from 'vant';

Vue.use(Tag);
Vue.use(Divider);

export default {
  name: "aiCharPre",
  components: {},
  data() {
    return {
      // 左侧高亮元素的index
      activeIndex: '',
      mainActiveIndex: '',
      // 被选中元素的id
      before: 'van-icon van-icon-arrow van-cell__right-icon',
      activeId: '',
      active: '',
      parentList: [],
      departmentList: [],
      items: [],
    }
  },
  created() {

  },
  mounted() {
    // this.getParentList();
  },
  methods: {
    alert1() {
      alert(1)
    }
  },
  watch: {
    activeId(newValue, oldValue) {
      alert('oldValue = ' + oldValue + ' newValue = ' + newValue)


    },
    active(newValue, oldValue) {
      //alert('oldValue = ' + oldValue + ' newValue = ' +newValue)
      console.log(newValue)
      console.log(oldValue)
      this.getDepartmentList(this.parentList[newValue].deptId)
    }
  }
  /*setup() {
    const active = ref(0);
    const onChange = (index) => Toast(`标签名 ${index + 1}`);
    return {
      active,
      onChange,
    };
  },*/
}
</script>

<style scoped>

</style>