<template>
  <div>
    <div style="margin: 20px 0;color: #08a67c;" @click="showActionSheet">时间：《{{ dateRange }}》</div>

    <div class="table-box">
      <van-row class="th-row">
        <van-col :span="24/dataList.title.length" v-for="warn in dataList.title" :key="warn">
          {{warn}}
        </van-col>
      </van-row>
      <van-row class="tb-row" v-for="(wb,index) in dataList.tbData" :key="index"
               :style="{background:index % 2 === 0 ?'#fff':'#F4F9FE'}">
        <van-col :span="24/Object.keys(wb).length" v-for="item in Object.keys(wb)" :key="item">{{wb[item]}}</van-col>
      </van-row>
    </div>

    <van-action-sheet
        v-model="showSheet"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @cancel="onCancel"
        @select="onSelect"
    />
  </div>



</template>
<script>

import axios from "axios";
import common from "@/utils/common";

export default {
  name: "countBlood",
  data() {
    return {
      showSheet: false,
      dateRange: '最近一个月',
      actions: [{name: '最近60天'}, {name: '最近一个月'}, {name: '最近半个月'}, {name: '最近一周'}],
      dataList: {
        title: ['数据分析', '最大值', '最小值', '平均值'],
        tbData: []
      },
      userInfo: [],
    };
  },
  mounted() {
    document.title = '血压数据统计'
    this.userInfo = JSON.parse(localStorage.getItem("userInfo" + localStorage.getItem('appid')));
    setTimeout(() => {
      this.getData(this.dateRange)
    }, 200);
  },
  methods: {

    onSelect(item) {

      console.log(item.name);
      this.dateRange = item.name;
      this.getData(this.dateRange);

    },
    showActionSheet() {
      this.showSheet = true;
    },
    onCancel() {

    },
    //获取数据
    getData(str) {
      const that = this;
      axios.post('/chronic/count', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
        "type": 1,
        "startDate": common.chanceDate(str)
      })
          .then(function (response) {
            that.dataList.tbData = [];
            that.dataList.tbData.push({depart: '高压', max: response.data.data[0], min: response.data.data[1], avg: Math.floor((response.data.data[0] + response.data.data[1]) / 2)});
            that.dataList.tbData.push({depart: '低压', max: response.data.data[2], min: response.data.data[3], avg: Math.floor((response.data.data[2] + response.data.data[3]) / 2)});
            that.dataList.tbData.push({depart: '心率', max: response.data.data[4], min: response.data.data[5], avg: Math.floor((response.data.data[4] + response.data.data[5]) / 2)});
          })
          .catch(function (error) {
            alert(error)
            console.log("请求失败 " + JSON.stringify(error));
          });
    },
  }
}
</script>
<style scoped  >



.table-box{
  margin: 12px 0;

}
.th-row{
  height: 36px;
  line-height: 36px;
  background: #EBF1F7;
  padding: 0 12px;
}
.tb-row{
  height: 36px;
  line-height: 36px;
  padding: 0 12px;
}
</style>