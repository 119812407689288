<template>
<!--  <div ref="scrollContainer" style="display: flex;align-items:center;justify-items: left;"
       :style="{'height':screenHeight }" class="chat-container">
    &lt;!&ndash; 遮罩层 &ndash;&gt;
    <van-overlay :show="showOverlay" @click="showOverlay = false"
                 :style="{ backgroundColor: 'rgba(100, 100, 100, 0.3)' }"/>
    <van-loading color="black" type="spinner" v-show="showLoading"
                 style="z-index: 3000;position:fixed;margin-top:50%;margin-left: 50%;"/>

    <div v-show="showTips"
         style="font-weight: 650;font-size: 22px;float: left;position: fixed;z-index: 1000;background: transparent; ; height: 100%;width: 100%;">
      <p style="justify-items: left;text-align: left;margin-left: 20px;">您好，我是AI智能客服。</p>
      <p style="font-size: 22px;text-align: left;margin-left: 20px;">您有什么问题都可以直接提问的~</p>
      <van-divider style="color: white;"/>
      <van-row gutter="20" style="margin: 30px;">
        <van-col span="20">
          <div style="float: left;">
            <span style="font-size: 16px;">请问您是遇到以下问题吗？</span>
          </div>
        </van-col>
        <van-col span="20">
          <div style="float: right;">
            <van-tag plain style="border: none;" mark type="success" size="large" @click="register()">挂号问题
            </van-tag>
          </div>
        </van-col>
      </van-row>
      <van-row gutter="20" style="margin: 30px;">

        <van-col span="20">
          <div style="float: right;">
            <van-tag plain mark color="#2e2e2e" size="large" @click="hospitalOnline">在线问诊</van-tag>
          </div>
        </van-col>
      </van-row>
      <van-row gutter="20" style="margin: 30px;">
        <van-col span="20">
          <div style="float: right;">
            <van-tag plain mark color="#2e2e2e" size="large" @click="buyMedicine">在线买药</van-tag>
          </div>
        </van-col>
      </van-row>
      <van-row gutter="20" style="margin: 30px;">
        <van-col span="20">
          <div style="float: right;">
            <van-tag plain mark color="#2e2e2e" size="large" @click="other">其他问题</van-tag>
          </div>
        </van-col>
      </van-row>

      <div style="bottom: 60px;position: absolute;margin-left: 20px;">
        <van-row gutter="24" style="display: flex;margin-bottom: 20px;">
          <van-col span="8">
            <div>
              <van-tag plain round type="success" size="large" @click="alert1">医院电话</van-tag>
            </div>
          </van-col>
          <van-col span="8">
            <div>
              <van-tag plain round type="success" size="large" @click="alert1">医院地址</van-tag>

            </div>
          </van-col>
          <van-col span="8">
            <div>
              <van-tag plain round type="success" size="large" @click="workTime">上班时间</van-tag>

            </div>
          </van-col>
        </van-row>
      </div>
    </div>-->
    <Chat v-if="visible"
          :participants="participants"
          :myself="myself"
          :messages="messages"
          :chat-title="chatTitle"
          :placeholder="placeholder"
          :colors="colors"
          :border-style="borderStyle"
          :hide-close-button="hideCloseButton"
          :close-button-icon-size="closeButtonIconSize"
          :submit-icon-size="submitIconSize"
          :submit-image-icon-size="submitImageIconSize"
          :load-more-messages="toLoad.length > 0 ? loadMoreMessages : null"
          :async-mode="asyncMode"
          :scroll-bottom="scrollBottom"
          :display-header="false"
          :send-images="true"
          :profile-picture-config="profilePictureConfig"
          :timestamp-config="timestampConfig"
          :link-options="linkOptions"
          :accept-image-types="'.png, .jpeg'"
          @onImageClicked="onImageClicked"
          @onImageSelected="onImageSelected"
          @onMessageSubmit="onMessageSubmit"
          @onType="onType"
          @onClose="onClose"
    />


<!--  </div>-->


</template>

<script>
import {Chat} from 'vue-quick-chat';
import 'vue-quick-chat/dist/vue-quick-chat.css';
import '../../style/vue-quick-chat.css';
import DraggableBall from "@/views/index/DraggableBall.vue";
import axios from 'axios'
import Vue from "vue";
import VueAxios from "vue-axios";
import {Toast} from 'vant';
import {Loading} from 'vant';
//import { Overlay } from 'vant';
import 'vant/lib/index.css';
import {Divider} from 'vant';
import {Tag} from 'vant';

Vue.use(Tag);
Vue.use(Divider);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(VueAxios, axios)

export default {
  components: {
    Chat,
  },
  name: "aiChat",
  data() {
    return {
      department: {
        "全科": 12,
        "妇科": 13,
        "口腔科": 14,
        "外科": 15,
        "专家门诊（儿科）": 16,
        "专家门诊（心内科）": 17,
        "皮肤科": 18,
        "五官科": 19,
        "中医康复科": 20
      },
      departmentArray: ["全科", "妇科", "口腔科", "外科", "专家门诊（儿科）", "专家门诊（心内科）", "皮肤科", "五官科", "中医康复科"],
      showOverlay: false,
      showLoading: false,
      showTips: true,
      newMessage:
          {
            content: "",
            myself: false,
            participantId: 1,
            timestamp: {},
            uploaded: true,
            viewed: true,
            type: 'text'
          },
      screenHeight: (screen.height - 100) + "px",
      visible: true,
      participants: [
        {
          //name: 'DeepSeek：',
          id: 1,
          profilePicture: require("@/assets/deepseek.png")
        }
      ],
      myself: {
        //name: '我：',
        id: 2,
        profilePicture: require("@/assets/question.png")
      },
      messages: [
        /*{
          content: '您好，我是AI智能客服',
          myself: false,
          participantId: 1,
          timestamp: {},
          type: 'text'
        },
        {
          content: '您可以咨询任何关于健康的问题，比如可以像这样直接发送：健康体重 理想血压',
          myself: false,
          participantId: 1,
          timestamp: {},
          type: 'text'
        },*/
        /*{
          content: 'other received messages',
          myself: false,
          participantId: 1,
          timestamp: {year: 2019, month: 5, day: 5, hour: 10, minute: 10, second: 3, millisecond: 123},
          type: 'text'
        }*/
      ],
      chatTitle: 'My chat title',
      placeholder: '请输入您的问题',
      colors: {
        header: {
          bg: '#08a67c',
          text: '#fff'
        },
        message: {
          myself: {
            bg: '#fff',
            text: '#bdb0b0'
          },
          others: {
            bg: '#08a67c',
            text: '#fff'
          },
          messagesDisplay: {
            bg: '#f7f3f3'
          }
        },
        submitIcon: '#08a67c',
        submitImageIcon: '#08a67c',
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: false,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      aiType: "",
      toLoad: [
        /*{
          content: '您好，我是AI智能客服。',
          myself: false,
          participantId: 1,
          timestamp: {},
          uploaded: true,
          viewed: true,
          type: 'text'
        },
        {
          content: '您可以直接点击下方标签进行提问，也可以输入您想问的其他问题',
          myself: false,
          participantId: 1,
          timestamp: {},
          uploaded: true,
          viewed: true,
          type: 'text'
        }*/
      ],
      myMessage:
          {
            content: '',
            myself: true,
            participantId: 1,
            timestamp: {},
            uploaded: true,
            viewed: true,
            type: 'text'
          },
      scrollBottom: {
        messageSent: true,
        messageReceived: false
      },
      displayHeader: true,
      profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
          width: '30px',
          height: '30px',
          borderRadius: '50%'
        }
      },
      timestampConfig: {
        format: 'HH:mm',
        relative: false
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          className: 'myLinkClass',
          events: {
            click: function (e) {
              console.log(e.message)
              //alert('Link clicked!');
            },
            mouseover: function (e) {
              console.log(e.message)
              //alert('Link hovered!');
            }
          },
          format: function (value, type) {
            if (type === 'url' && value.length > 50) {
              value = value.slice(0, 50) + '…';
            }
            return value;
          }
        },
        others: {
          className: 'othersLinkClass',
          events: {
            click: function (e) {
              console.log(e.message)
              //alert('Link clicked!' + e);
            },
            mouseover: function (e) {
              console.log(e.message)
              //alert('Link hovered!' + e);
            }
          },
          format: function (value, type) {
            if (type === 'url' && value.length > 50) {
              value = value.slice(0, 50) + '…';
            }
            return value;
          }
        }
      },
      questionList:
          ["  append<> 优先考虑以下科室挂号，不用说关联性低的科室，如果跟挂号看病不沾边也可以正常回答",
            "",
            "",
            "",
          ],
      questionType: ""
    }
  },
  methods: {
    //挂号问题
    register() {
      //点击挂号，自动回复，请输入您现在的症状并发送，比如：感冒
      this.questionType = this.questionList[0];
      const newObj = {...this.newMessage};
      newObj.content = "请输入您现在的症状并发送，比如：感冒";
      newObj.type = "text";
      this.messages.push(newObj);

      //隐藏提示文字
      this.showTips = false;
    },
    //在线问诊
    hospitalOnline() {
      Toast('暂未开通，打开互联网医院小程序');
    },
    buyMedicine() {
      Toast('暂未开通,输入症状自动推送药品');
    },

    other() {
      this.questionType = this.questionList[4];
      const newObj = {...this.newMessage};
      newObj.content = "请输入您的问题并发送";
      newObj.type = "text";
      this.messages.push(newObj);

      //隐藏提示文字
      this.showTips = false;
    },
    //上班时间
    workTime() {
      const newObj = {...this.myMessage};
      newObj.content = "上班时间";
      newObj.type = "text";
      this.onMessageSubmit(newObj);
    },

    alert1() {
      Toast('暂未开通');
    },

    onType: function (event) {
      console.log(event.message)
      //here you can set any behavior
    }
    ,
    loadMoreMessages(resolve) {
      console.log(resolve)
      /*this.aiType = this.$route.query.keyword
      if (this.aiType === "department") {
        setTimeout(() => {
          this.messages = [];//清空默认
          resolve(this.toLoad); //We end the loading state and add the messages
          //Make sure the loaded messages are also added to our local messages copy or they will be lost
          this.messages.unshift(...this.toLoad);
          this.toLoad = [];
        }, 1000);
      } else {
        resolve(this.toLoad);

        this.toLoad = [];
      }*/
    }
    ,
    onMessageSubmit: function (message) {
      /*
      * example simulating an upload callback.
      * It's important to notice that even when your message wasn't send
      * yet to the server you have to add the message into the array
      */

      this.sendToServer(message);
      //this.scrollToBottom();
      this.messages.push(message);
      /*
      * you can update message state after the server response
      */
      // timeout simulating the request
      setTimeout(() => {
        message.uploaded = true
      }, 2000)
    }
    ,
    // 模拟发送消息到服务器的方法（实际应用中应调用API）
    sendToServer(message) {


      //alert(message.content)
      // 使用axios或其他HTTP库发送数据到服务器
      //this.messages.push({text: this.newMessage, isUser: true}); // 添加用户发送的消息到数组中，并标记为用户的消息
      //alert('code = ' + code)
      let that = this;
      /*Toast.loading({
        mask: true,
        message: '加载中...'
      });*/
      that.showTips = false;
      that.showLoading = true;
      that.showOverlay = true;

      //let sentMessage = message.content;

      //拼接挂号问题，互联网医院小程序，商品等
      let question = message.content;
      question += this.questionType;

      //发送数据到服务器
      axios.post('api/deepseek/chat', {
        "content": question + " append<>，稍微简短点回答即可",
        "userId": 1,
        "companyId": 65,
      })
          .then(function (response) {
            console.log(response);
            const newObj = {...that.newMessage};
            let content = response.data.choices[0].message.content;

            content = content.replaceAll('*', '');

            //关联性较低后面的都不要了
            if (content.indexOf("关联性较低") !== -1) {
              content = content.substring(0, content.indexOf("关联性较低"))
            } else if (content.indexOf("其他科室") !== -1) {
              content = content.substring(0, content.indexOf("其他科室"))
            }
            else if (content.indexOf("其他关联性") !== -1) {
              content = content.substring(0, content.indexOf("其他关联性"))
            }

            newObj.content = content;
            that.messages.push(newObj);

            //自动推荐科室
            that.autoRecommendDepart(content);
          })
          .catch(function (error) {
            alert(error.message)
          }).finally(function () {
        that.showLoading = false;
        that.showOverlay = false;
      });

    }
    ,
    //自动推荐科室
    autoRecommendDepart(content) {


      console.log(content);
      let count = 0;//最多推荐4个
      //遍历科室  //查找字符串是否包含科室
      for (let i = 0; i < this.departmentArray.length; i++) {
        if (content.includes("不确定时可先挂全科")) {
          const newObj = {...this.newMessage};
          newObj.content = "立即挂号";
          newObj.type = "text";
          this.messages.push(newObj);
          break;
        }

        console.log("item =======>> " + this.departmentArray[i])
        if (content.includes(this.departmentArray[i])) {
          if (count > 2) {
            break;
          }
          count++;
          const newObj = {...this.newMessage};
          const domain = window.location.origin;
          //const content = response.data.choices[0].message.content;
          //localStorage.setItem('departmentTitle',this.departmentArray[i]);
          newObj.content = "点击链接立即挂号：" + this.departmentArray[i] + domain + '/#/doctor?index=' + this.department[this.departmentArray[i]] + "&depart=" + this.departmentArray[i];
          newObj.type = "href";
          this.messages.push(newObj);

        }

      }
      //插入科室链接
    },
    // 使用 window.scrollTo 滚动到页面最底部
    scrollToBottom() {
      window.scrollTo({
        top: document.body.scrollHeight + 300,
        behavior: 'smooth' // 可选：平滑滚动
      });
      //const container = this.$refs.scrollContainer;
      //container.scrollTop = container.scrollHeight;
    }
    ,
    onClose() {
      this.visible = false;
      DraggableBall.showChat = false;
    }
    ,
    onImageSelected(files, message) {
      let src = 'https://149364066.v2.pressablecdn.com/wp-content/uploads/2017/03/vue.jpg'
      this.messages.push(message);
      /**
       * This timeout simulates a requisition that uploads the image file to the server.
       * It's up to you implement the request and deal with the response in order to
       * update the message status and the message URL
       */
      setTimeout((res) => {
        message.uploaded = true
        message.src = res.src
      }, 3000, {src});
    }
    ,
    onImageClicked(message) {
      /**
       * This is the callback function that is going to be executed when some image is clicked.
       * You can add your code here to do whatever you need with the image clicked. A common situation is to display the image clicked in full screen.
       */
      alert(message.src);
      console.log('Image clicked', message.src)
    }
  }
}
</script>

<style scoped>
.quick-chat-container .container-message-display {
  //margin-bottom: 80px;
}

.quick-chat-container .container-message-manager {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.quick-chat-container {
  border-radius: 0;
}

.chat-container {
  height: 900px;
  overflow-y: auto;
}

.van-tag--large {
  padding: 8px 12px;
}
</style>