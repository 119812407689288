<template>
  <div :style="{'height':swipeHeight}" style="background: #f7f8fd;width: 100%;">
    <div
        style="margin: 12px;border-radius: 10px;height: 260px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">
      <van-image
          style="margin-left: 30px;margin-top: 15px;float: left;margin-right: 20px;"
          round
          width="60px"
          height="60px"
          :src=userInfo.avatar
      />

      <div style="line-height: 60px;padding-left: 20px;font-size: medium;font-weight: 550;">
        {{ userInfo.phone != null ? userInfo.phone : "未绑定手机" }}
      </div>

      <van-divider/>
      <div style="height: 40px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">姓名</span>
        <span style="text-align: right;float: right; font-size: small;color: #565656;"
              @click="paymentTest">{{ userInfo.name }}</span>
      </div>
      <div style="height: 40px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">性别</span>
        <span
            style="text-align: right;float: right; font-size: small;color: #565656;"
            @click="cleanCache">{{ userInfo.gender === 1 ? '男' : '女' }}</span>
      </div>
      <div style="height: 40px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">出生年月</span>
        <span style="text-align: right;float: right; font-size: small;color: #565656;">{{ userInfo.birthday }}</span>
      </div>
      <div style="height: 40px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">身份证号</span>
        <span
            style="text-align: right;float: right; font-size: small;color: #565656;">{{ userInfo.identityCard }}</span>
      </div>

    </div>

    <!--    <div style="background: white;">
          &lt;!&ndash;<van-cell is-link icon="phone-o" :value="userInfo.phone" to="bindPhone">
            <template #title  >
              <span class="custom-title" >绑定手机</span>
            </template>
          </van-cell>&ndash;&gt;

          <van-cell is-link icon="hotel-o" class="van-ellipsis" :value="clinicName" to="DefaultClinic">
            <template #title  >
              <span class="custom-title" >机构设置</span>
            </template>
          </van-cell>

        </div>-->

  </div>
</template>

<script>
import Vue from 'vue';
import {Cell, CellGroup} from 'vant';

Vue.use(Cell);
Vue.use(CellGroup);
export default {
  name: "setting",
  data() {
    return {
      active: 'user',
      userInfo: {
        phone: "13978912345",
        gender: 1,
        avatar: "1",
    },
      clinicInfo: [],
      clinicName: ''
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    cleanCache() {
      localStorage.clear();
      alert("done")
    },
    paymentTest() {
      //Dialog.alert('客服微信号复制成功，请添加客服微信');

      //localStorage.setItem('userInfo' + appid, null);
      //localStorage.removeItem('userInfo');

      this.$router.push('ProductList')
    },
    gender(gender) {
      if (gender == 1)
        return '男'
      else
        return '女'
    },
    goReserve() {
      this.$router.push('reserveList')
    },
    getUserInfo() {

      //localStorage.setItem('userInfo' + appid, response.data.data)
      this.userInfo = JSON.parse(localStorage.getItem("userInfo" + localStorage.getItem('appid')));
      //this.clinicName = this.userInfo.default_company.title;

      /*let that = this;
      axios.defaults.headers.get['Accept'] = 'application/json'
      axios.get('/api/user')
          .then(function (response) {
            //alert('获取用户信息成功 ：' + JSON.stringify(response))
            console.log("用户信息")
            console.log(JSON.stringify(response.data.data))
            localStorage.setItem('userInfo' + appid, response.data.data)
            that.userInfo = response.data.data;
            that.clinicName = that.userInfo.default_company.title;
          })
          .catch(function (error) {
            //alert("获取用户信息失败22：" + JSON.stringify(error));
            console.log("获取用户信息失败22：" + JSON.stringify(error));
            //返回首页
            this.$router.replace({name: 'index'})

          });*/
    },
  },
  computed: {
    swipeHeight: function () {
      let height = document.documentElement.clientHeight;//屏幕高

      return height + 'px';
    }
  }
}
</script>

<style scoped>
.van-cell__title, .van-cell__value {
  /*text-align: start;*/
}

.custom-title {
  color: #565656;
  font-size: medium;
}
</style>