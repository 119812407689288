import Vue from 'vue'
import Router from 'vue-router'
//自定义页面
import Index from '@/views/index/Index'
import News from '@/views/news/News'
import User from '@/views/user/User'
import ReserveList from '@/views/reserve/List'
import ReserveDetail from '@/views/reserve/Detail'
import Clinic from '@/views/clinic/Index'
import Payment from '@/views/index/Payment'
//import ChooseClinic from '@/views/clinic/ChooseClinic'
import Vaccine from '@/views/vaccin/Vaccine'
import ClinicList from '@/views/clinic/List'
import Step1 from '@/views/reserve/Step1'
import Step2 from '@/views/reserve/Step2'
import Step3 from '@/views/reserve/Step3'
import FamilyList from '@/views/family/List'
import FamilyAdd from '@/views/family/Add'
import FamilyEdit from '@/views/family/Edit'
import Article from "@/views/clinic/Article";
import My from "@/views/clinic/My";
import newsDetail from "@/views/news/Detail";
import ClinicInfo from "@/views/clinic/Info";
import SubscribeList from "@/views/subscribe/List";
import SubscribeDetail from "@/views/subscribe/Detail"
import SubscribeIndex from "@/views/subscribe/Index"
import Collection from "@/views/clinic/Collection"
import Setting from "@/views/user/Setting"
import DoctorList from "@/views/doctor/List"
import DoctorForm from "@/views/doctor/Form"
import DoctorOrder from "@/views/doctor/Order"
import QueueIndex from "@/views/queue/Index"
import Scan from "@/views/user/Scan"
import Insurance from "@/views/index/Insurance.vue"
import InsuranceList from "@/views/clinic/InsuranceList"
import DefaultClinic from "@/views/clinic/DefaultClinic"
import ChooseProduct from "@/views/clinic/ChooseProduct"
import ReserveEdit from "@/views/reserve/Edit"
import Area from "@/views/index/Area"
import BindPhone from "@/views/user/BindPhone"
import Product from "@/views/clinic/Product"
import NoticeList from "@/views/clinic/NoticeList"
import NoticeDetail from "@/views/clinic/NoticeDetail"
import QueueDetail from "@/views/queue/Detail"
import ReSuccess from "@/views/reserve/ReSuccess"
import QuSuccess from "@/views/queue/QuSuccess"
import QuSubmitSuccess from "@/views/queue/QuSubmitSuccess"
import Warning from "@/views/reserve/Warning"
import Share from "@/views/index/Share"
import SubSuccess from "@/views/subscribe/SubSuccess"
import NucleinStep1 from "@/views/nuclein/Step1.vue"
import NucleinStep2 from "@/views/nuclein/Step2.vue"
import NucleinStep3 from "@/views/nuclein/Step3.vue"
import NucleinDetail from "@/views/nuclein/Detail.vue"
import QueueList from "@/views/queue/List.vue"
import Department from "@/views/index/Department.vue"
import Doctor from "@/views/index/Doctor.vue"
import DoctorConfirm from "@/views/index/DoctorConfirm.vue"
import DoctorDetail from "@/views/index/DoctorDetail.vue"
import RegistrationList from "@/views/index/registrationList.vue"
import RegistrationDetail from "@/views/index/registrationDetail.vue"
import MallProductList from "@/views/mall/ProductList.vue"
import MallProductDetail from "@/views/mall/ProductDetail.vue"
import MallProductConfirm from "@/views/mall/ProductConfirm.vue"
import Report from "@/views/chronic/Report.vue"
import MyData from "@/views/chronic/MyData.vue"
import AddBloodPressure from "@/views/chronic/AddBloodPressure.vue"
import AddCholesterol from "@/views/chronic/AddCholesterol.vue"
import AddUric from "@/views/chronic/AddUric.vue"
import Upload from "@/views/chronic/Upload.vue"
import AllBloodData from "@/views/chronic/AllBloodData.vue"
import AllSugarData from "@/views/chronic/AllSugarData.vue"
import AllUricData from "@/views/chronic/AllUricData.vue"
import AllCholesterolData from "@/views/chronic/AllCholesterolData.vue"
import CountBlood from "@/views/chronic/CountBlood.vue"
import CountSugar from "@/views/chronic/CountSugar.vue"
import CountUric from "@/views/chronic/CountUric.vue"
import CountCholesterol from "@/views/chronic/CountCholesterol.vue"
import ParentingStart from "@/views/parenting/ParentingStart.vue"
import ParentingSetting from "@/views/parenting/ParentingSetting.vue"
import ParentingHome from "@/views/parenting/ParentingHome.vue"
import ParentingCategory from "@/views/parenting/ParentingCategory.vue"
import ParentingArticle from "@/views/parenting/ParentingArticle.vue"
import ParentingList from "@/views/parenting/ParentingList.vue"
import AiChat from "@/views/index/AiChat.vue"
import MTProcess from "@/views/index/MTProcess.vue"
import MyDoctor from "@/views/index/MyDoctor.vue"
import Advisory from "@/views/index/Advisory.vue"
import Lineup from "@/views/index/Lineup.vue"
import Registration from "@/views/index/Registration.vue"
import PaymentList from "@/views/index/PaymentList.vue"
import OutpatientList from "@/views/index/OutpatientList.vue"
import AiChatPre from "@/views/index/AiChatPre.vue"


//安装路由到vue
Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/index',  //重定向，设置默认进入 页面
        children: [
            /*{
                path: '/pageA',
                name: 'pageA',
                component: PageA
            }*/
        ],
        meta: {
            keepAlive: false
        }
    }, {
        path: '/AiChat',
        name: 'aiChat',
        component: AiChat,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/AiChatPre',
        name: 'aiChatPre',
        component: AiChatPre,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Advisory',
        name: 'advisory',
        component: Advisory,
        meta: {
            keepAlive: false
        }
    },{
        path: '/Registration',
        name: 'registration',
        component: Registration,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/MyDoctor',
        name: 'myDoctor',
        component: MyDoctor,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/MTProcess',
        name: 'MTProcess',
        component: MTProcess,
        meta: {
            keepAlive: false
        }
    },{
        path: '/Lineup',
        name: 'lineup',
        component: Lineup,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/OutpatientList',
        name: 'outpatientList',
        component: OutpatientList,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/index',
        name: 'index',
        component: Index,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/report',
        name: 'report',
        component: Report,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/myData',
        name: 'myData',
        component: MyData,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/addBloodPressure',
        name: 'addBloodPressure',
        component: AddBloodPressure,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/addCholesterol',
        name: 'addCholesterol',
        component: AddCholesterol,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/addUric',
        name: 'addUric',
        component: AddUric,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/upload',
        name: 'upload',
        component: Upload,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/allBloodData',
        name: 'allBloodData',
        component: AllBloodData,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/allSugarData',
        name: 'allSugarData',
        component: AllSugarData,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/allUricData',
        name: 'allUricData',
        component: AllUricData,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/allCholesterolData',
        name: 'allCholesterolData',
        component: AllCholesterolData,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/countCholesterol',
        name: 'countCholesterol',
        component: CountCholesterol,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/countBlood',
        name: 'countBlood',
        component: CountBlood,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/countSugar',
        name: 'countSugar',
        component: CountSugar,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/countUric',
        name: 'countUric',
        component: CountUric,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/parentingStart',
        name: 'parentingStart',
        component: ParentingStart,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/parentingSetting',
        name: 'parentingSetting',
        component: ParentingSetting,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/parentingHome',
        name: 'parentingHome',
        component: ParentingHome,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/parentingArticle',
        name: 'parentingArticle',
        component: ParentingArticle,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/parentingCategory',
        name: 'parentingCategory',
        component: ParentingCategory,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/parentingList',
        name: 'parentingList',
        component: ParentingList,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/news',
        name: 'news',
        component: News
    }, {
        path: '/newsDetail',
        name: 'newsDetail',
        component: newsDetail
    }, {
        path: '/productList',
        name: 'ProductList',
        component: MallProductList
    }, {
        path: '/productDetail',
        name: 'ProductDetail',
        component: MallProductDetail
    }, {
        path: '/productConfirm',
        name: 'ProductConfirm',
        component: MallProductConfirm
    }, {
        path: '/user',
        name: 'user',
        component: User,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/reserveList',
        name: 'reserveList',
        component: ReserveList
    }, {
        path: '/reserveDetail',
        name: 'reserveDetail',
        component: ReserveDetail
    }, {
        path: '/vaccine',
        name: 'vaccine',
        component: Vaccine
    }, {
        path: '/payment',
        name: 'payment',
        component: Payment
    }, {
        path: '/PaymentList',
        name: 'paymentList',
        component: PaymentList
    }, {
        path: '/clinic',
        name: 'clinic',
        component: Clinic,
    }, {
        path: '/nucleinStep1',
        name: 'nucleinStep1',
        component: NucleinStep1,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/nucleinStep2',
        name: 'nucleinStep2',
        component: NucleinStep2,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/nucleinStep3',
        name: 'nucleinStep3',
        component: NucleinStep3,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/nucleinDetail',
        name: 'nucleinDetail',
        component: NucleinDetail,
        meta: {
            keepAlive: false
        }
    },/* {
        path: '/chooseClinic',
        name: 'chooseClinic',
        component: ChooseClinic
    },*/ {
        path: '/clinicList',
        name: 'clinicList',
        component: ClinicList,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/step1',
        name: 'step1',
        component: Step1
    }, {
        path: '/step2',
        name: 'step2',
        component: Step2
    }, {
        path: '/step3',
        name: 'step3',
        component: Step3
    }, {
        path: '/familyList',
        name: 'familyList',
        component: FamilyList
    }, {
        path: '/familyAdd',
        name: 'familyAdd',
        component: FamilyAdd
    }, {
        path: '/familyEdit',
        name: 'familyEdit',
        component: FamilyEdit
    }, {
        path: '/article',
        name: 'article',
        component: Article
    }, {
        path: '/my',
        name: 'my',
        component: My
    }, {
        path: '/clinicInfo',
        name: 'clinicInfo',
        component: ClinicInfo
    }, {
        path: '/subscribeList',
        name: 'subscribeList',
        component: SubscribeList
    }, {
        path: '/subscribeDetail',
        name: 'subscribeDetail',
        component: SubscribeDetail
    }, {
        path: '/subscribeIndex',
        name: 'subscribeIndex',
        component: SubscribeIndex
    }, {
        path: '/collection',
        name: 'collection',
        component: Collection
    }, {
        path: '/setting',
        name: 'setting',
        component: Setting
    }, {
        path: '/doctorList',
        name: 'doctorList',
        component: DoctorList
    }, {
        path: '/doctorForm',
        name: 'doctorForm',
        component: DoctorForm
    }, {
        path: '/doctorOrder',
        name: 'doctorOrder',
        component: DoctorOrder
    }, {
        path: '/queueIndex',
        name: 'queueIndex',
        component: QueueIndex,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/scan',
        name: 'scan',
        component: Scan
    }, {
        path: '/insurance',
        name: 'insurance',
        component: Insurance
    }, {
        path: '/InsuranceList',
        name: 'InsuranceList',
        component: InsuranceList
    }, {
        path: '/DefaultClinic',
        name: 'DefaultClinic',
        component: DefaultClinic
    }, {
        path: '/chooseProduct',
        name: 'chooseProduct',
        component: ChooseProduct
    }, {
        path: '/reserveEdit',
        name: 'reserveEdit',
        component: ReserveEdit
    }, {
        path: '/area',
        name: 'area',
        component: Area
    }, {
        path: '/bindPhone',
        name: 'bindPhone',
        component: BindPhone
    }, {
        path: '/product',
        name: 'product',
        component: Product
    }, {
        path: '/noticeList',
        name: 'noticeList',
        component: NoticeList
    }, {
        path: '/noticeDetail',
        name: 'noticeDetail',
        component: NoticeDetail
    }, {
        path: '/queueDetail',
        name: 'queueDetail',
        component: QueueDetail
    }, {
        path: '/queueList',
        name: 'queueList',
        component: QueueList
    }, {
        path: '/reSuccess',
        name: 'reSuccess',
        component: ReSuccess
    }, {
        path: '/SubSuccess',
        name: 'SubSuccess',
        component: SubSuccess
    }, {
        path: '/quSuccess',
        name: 'quSuccess',
        component: QuSuccess,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/quSubmitSuccess',
        name: 'quSubmitSuccess',
        component: QuSubmitSuccess
    }, {
        path: '/warning',
        name: 'warning',
        component: Warning
    }, {
        path: '/department',
        name: 'department',
        component: Department,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/doctor',
        name: 'doctor',
        component: Doctor,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/doctorDetail',
        name: 'doctorDetail',
        component: DoctorDetail,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/doctorConfirm',
        name: 'doctorConfirm',
        component: DoctorConfirm,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/registrationList',
        name: 'registrationList',
        component: RegistrationList,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/registrationDetail',
        name: 'registrationDetail',
        component: RegistrationDetail,
        meta: {
            keepAlive: false
        }
    }, {
        path: '/share',
        name: 'share',
        component: Share,
        meta: {
            keepAlive: false
        }
    }
]

export default new Router({
    //mode: 'history',
    //mode: 'hash', //默认hash模式，hash模式有#；另外还有一种history模式，没有#符号
    routes
})