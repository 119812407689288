<template>
  <div>
    <van-tabs v-model="activeTab" @click="onTabChange" animated swipeable color='#08a67c'>
      <van-tab title="血压"  >
        <div>
          <div style="margin: 20px 0;color:  #08a67c;" @click="showActionSheet">时间：《{{ dateRange }}》</div>
          <canvas id="line-chart"></canvas>

          <div style="margin-top: 20px;">
            <van-cell icon="notes-o" to="allBloodData" title="所有血压数据" is-link/>
            <van-cell icon="chart-trending-o" to="countBlood" title="数据统计" is-link/>
            <van-cell icon="add-o" style="color: #08a67c" @click="goAddData(0)" title="添加血压数据" is-link/>
          </div>
        </div>

      </van-tab>
      <van-tab title="血糖" >
        <div>
          <div style="margin: 20px 0;color:  #08a67c;" @click="showActionSheet" >时间：《{{ dateRange }}》</div>
          <canvas id="line-chart2"></canvas>

          <div style="margin-top: 20px;">
            <van-cell icon="notes-o" to="allSugarData" title="所有血糖数据" is-link/>
            <van-cell icon="chart-trending-o" to="countSugar" title="数据统计" is-link/>
            <van-cell icon="add-o" style="color: #08a67c" @click="goAddData(1)" title="添加血糖数据" is-link/>
          </div>
        </div>
      </van-tab>
      <van-tab title="尿酸" >
        <div>
          <div style="margin: 20px 0;color:  #08a67c;" @click="showActionSheet">时间：《{{ dateRange }}》</div>
          <canvas id="line-chart3"></canvas>

          <div style="margin-top: 20px;">
            <van-cell icon="notes-o" to="allUricData" title="所有尿酸数据" is-link/>
            <van-cell icon="chart-trending-o" to="countUric" title="数据统计" is-link/>
            <van-cell icon="add-o" to="AddUric" style="color: #08a67c"  title="添加尿酸数据" is-link/>
          </div>
        </div>

      </van-tab>
      <van-tab title="胆固醇">
        <div>
          <div style="margin: 20px 0;color:  #08a67c;" @click="showActionSheet">时间：《{{ dateRange }}》</div>
          <canvas id="line-chart4"></canvas>

          <div style="margin-top: 20px;">
            <van-cell icon="notes-o" to="allCholesterolData" title="所有胆固醇数据" is-link/>
            <van-cell icon="chart-trending-o" to="countCholesterol" title="数据统计" is-link/>
            <van-cell icon="add-o" style="color: #08a67c" to="addCholesterol" title="添加胆固醇数据" is-link/>
          </div>
        </div>

      </van-tab>
      <van-tab to="upload" title="处方单"></van-tab>
      <van-action-sheet
          v-model="showSheet"
          :actions="actions"
          cancel-text="取消"
          close-on-click-action
          @cancel="onCancel"
          @select="onSelect"
      />
    </van-tabs>
  </div>

</template>
<script>
import Chart from "chart.js";
import axios from "axios";
import common from "@/utils/common";

export default {
  name: "MyData",
  data() {
    return {
      dateRange: '最近一个月',
      actions: [{name: '最近60天'}, {name: '最近一个月'}, {name: '最近半个月'}, {name: '最近一周'}],
      showSheet: false,
      activeTab: 0,
      canClick: true,
      labels1: [],
      data11: [],
      data12: [],
      data13: [],
      labels2: [],
      data2: [],
      labels3: [],
      data3: [],
      labels4: [],
      data4: [],
      userInfo: [],
    };
  },
  mounted() {
    let appid = localStorage.getItem('appid');
    this.userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
    document.title = '我的数据'
    this.activeTab = this.$route.query.index;

    switch (this.activeTab) {
      case 1:
        setTimeout(() => {
          // 休眠后的操作
          this.getSugar(this.dateRange)
        }, 1000);
        break;

      case 2:
        setTimeout(() => {
          // 休眠后的操作
          this.getUric(this.dateRange)
        }, 1000);
        break;

      case 3:
        setTimeout(() => {
          // 休眠后的操作
          this.getCholesterol(this.dateRange)
        }, 1000);
        break;

      default:
        // 休眠1秒
        setTimeout(() => {
          // 休眠后的操作
          this.getPressure(this.dateRange);
        }, 1000);
    }

  },
  methods: {
    goAddData(index) {
      this.$router.push({
        name: 'addBloodPressure',
        params: {
          index: index
        },
        query: {
          index: index
        }
      });
    },
    onSelect(item) {

      console.log(item.name);
      //Toast(this.activeTab);
      this.dateRange = item.name;
      switch(this.activeTab) {
        case 1:
          this.getSugar(item.name);
          break;
        case 2:
          this.getUric(item.name);
          break;
        case 3:
          this.getCholesterol(item.name);
          break;
        default:
          this.getPressure(item.name);
          break;
      }
    },
    showActionSheet() {
      this.showSheet = true;
    },
    onCancel() {

    },

    onTabChange(index, title) {
      console.log('title ============================ ');
      console.log(title);

      console.log('index ============================ ');
      console.log(index);

      const that = this;

      switch (index) {
        case 1:
          setTimeout(() => {
            // 休眠后的操作
            that.getSugar(this.dateRange)
          }, 1000);
          break;

        case 2:
          setTimeout(() => {
            // 休眠后的操作
            that.getUric(this.dateRange)
          }, 1000);
          break;

        case 3:
          setTimeout(() => {
            // 休眠后的操作
            that.getCholesterol(this.dateRange)
          }, 1000);
          break;

        default:
          // 休眠1秒
          setTimeout(() => {
            // 休眠后的操作
            that.getPressure(this.dateRange)
          }, 1000);
      }
    },
    showPressure() {
      const ctx = document.getElementById('line-chart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.labels1,
          datasets: [{
            label: '高压',
            data: this.data11,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }, {
            label: '低压',
            data: this.data12,
            fill: false,
            borderColor: 'rgb(200, 192, 75)',
            tension: 0.1
          }, {
            label: '心率',
            data: this.data13,
            fill: false,
            borderColor: 'rgb(100, 90, 175)',
            tension: 0.1
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true
              }
            }]
          }
        }
      });
    },
    showSugar() {
      const ctx2 = document.getElementById('line-chart2').getContext('2d');
      new Chart(ctx2, {
        type: 'line',
        data: {
          labels: this.labels2,
          datasets: [{
            label: '血糖',
            data: this.data2,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        },
        options: {
          pointDot: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true
              }
            }]
          }
        }
      });
    },
    showUric() {
      const ctx2 = document.getElementById('line-chart3').getContext('2d');
      new Chart(ctx2, {
        type: 'line',
        data: {
          labels: this.labels3,
          datasets: [{
            label: '尿酸',
            data: this.data3,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        },
        options: {
          pointDot: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true
              }
            }]
          }
        }
      });
    },
    showCholesterol() {
      const ctx2 = document.getElementById('line-chart4').getContext('2d');
      new Chart(ctx2, {
        type: 'line',
        data: {
          labels: this.labels4,
          datasets: [{
            label: '胆固醇',
            data: this.data4,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        },
        options: {
          pointDot: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true
              }
            }]
          }
        }
      });
    },

    //获取血压数据
    getPressure(str) {
      const that = this;
      axios.post('/chronic/index', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
        "type": 1,
        "startDate": common.chanceDate(str)
      })
          .then(function (response) {
            that.labels1 = [];
            that.data11 = [];
            that.data12 = [];
            that.data13 = [];
            response.data.data.forEach(function (element) {
              that.labels1.push(element.dateTime.substring(5));
              that.data11.push(element.value1);
              that.data12.push(element.value2);
              that.data13.push(element.value3);
            })

            that.showPressure();
          })
          .catch(function (error) {
            console.log("请求失败 " + JSON.stringify(error));

          });
    },
    //获取血糖数据
    getSugar(str) {
      const that = this;
      axios.post('/chronic/index', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
        "type": 2,
        "startDate": common.chanceDate(str)
      })
          .then(function (response) {
            that.labels2 = [];
            that.data2 = [];
            response.data.data.forEach(function (element) {
              that.labels2.push(element.dateTime.substring(5));
              that.data2.push(element.value1);
            })
            that.showSugar();
          })
          .catch(function (error) {
            console.log("请求失败 " + JSON.stringify(error));
          });
    },
    //获取尿酸数据
    getUric(str) {
      const that = this;
      axios.post('/chronic/index', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
        "type": 3,
        "startDate": common.chanceDate(str)
      })
          .then(function (response) {
            that.labels3 = [];
            that.data3 = [];
            response.data.data.forEach(function (element) {
              that.labels3.push(element.dateTime.substring(5));
              that.data3.push(element.value1);
            })
            that.showUric();
          })
          .catch(function (error) {
            console.log("请求失败 " + JSON.stringify(error));
          });
    },
    //获取胆固醇数据
    getCholesterol(str) {
      const that = this;
      axios.post('/chronic/index', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
        "type": 4,
        "startDate": common.chanceDate(str)
      })
          .then(function (response) {
            that.labels4 = [];
            that.data4 = [];
            response.data.data.forEach(function (element) {
              that.labels4.push(element.dateTime.substring(5));
              that.data4.push(element.value1);
            })
            that.showCholesterol();
          })
          .catch(function (error) {
            console.log("请求失败 " + JSON.stringify(error));
          });
    },
  }
}
</script>
<style scoped>

</style>