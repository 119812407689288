<template>
  <!--  <van-sidebar v-model="active" @change="onChange">
      <span v-for="(item, index) in parentList" :key="index">
        <van-sidebar-item :title=item.title />
      </span>

    </van-sidebar>-->
  <div>
    <div id="app">
      <draggable-ball></draggable-ball>
    </div>
    <div style="padding: 30px 20px;text-align: left;border-bottom: #eeeeee solid;">
      <span style="float: left;font-size: large;line-height: 10px;">挂号记录</span>
    </div>
<!--    <van-tree-select height="200vw" :items="items" :active-id.sync="activeId" :main-active-index.sync="active">
      <template #content>

      <span v-for="(item, index) in departmentList" :key="index">
        <van-cell is-link @click="doctor(item.deptId, item.title)">
          <template #title style="text-align: start;">
            <span class="custom-title">{{ item.title }}</span>
          </template>
        </van-cell>
      </span>

      </template>

    </van-tree-select>-->
    <van-empty v-if="departmentList.length === 0" description="暂无记录">
    </van-empty>
  </div>

</template>

<script>

import axios from "axios";
import {TreeSelect} from 'vant';
import Vue from "vue";
import comFun from '../../utils/jssdk';

Vue.prototype.comFun = comFun;

Vue.use(TreeSelect);
/*挂号记录*/
export default {
  name: "registration",
  components: {

  },
  data() {
    return {
      // 左侧高亮元素的index
      activeIndex: '',
      mainActiveIndex: '',
      // 被选中元素的id
      before: 'van-icon van-icon-arrow van-cell__right-icon',
      activeId: '',
      active: '',
      parentList: [],
      departmentList: [],
      items: [],
    }
  },
  created() {

  },
  mounted() {
    // this.getParentList();
  },
  methods: {
    //获取公司信息
    getCompanyInfo(appid) {
      let that = this;
      axios.post('/companies/appId', {
        "appId": appid
      })
          .then(function (response) {
            localStorage.setItem('companyId', JSON.stringify(response.data.data.id))
            document.title = response.data.data.title;
            that.getParentList();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    onChange() {
      alert(this.activeId)
      alert(this.active)

    },
    doctor(index, title) {
      localStorage.setItem('departmentTitle', title)
      this.$router.push({
        name: 'doctor',
        params: {
          index: index
        },
        query: {
          index: index
        }
      });
    },
    getParentList() {
      let that = this;
      let appid = localStorage.getItem('appid');
      let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
      console.log(userInfo) //todo
      axios.post('/department/dropDown', {
        "modelType": "App\\Queues",
        "userId": localStorage.getItem("userId"), // todo userInfo.id,
        "state": 1,
        "title": "_",
        "parentId": 0,
        "page": 1,
        "per_page": 20,
        "companyId": localStorage.getItem("companyId"),// todo userInfo.companyId
      })
          .then(function (response) {
            that.parentList = response.data.data;
            that.parentList.forEach(function (element) {
              console.log('element = ' + JSON.stringify(element))
              let obj = {};
              obj.text = element.title
              that.items.push(obj)
            });
            that.getDepartmentList(that.parentList[0].deptId)

            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getDepartmentList(curIndex) {
      let that = this;
      // todo let appid = localStorage.getItem('appid');
      // todo let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));

      axios.post('/department/list', {
        "modelType": "App\\Queues",
        "userId": localStorage.getItem("userId"), // todo userInfo.id,
        "state": 1,
        "title": "_",
        "parentId": curIndex,
        "page": 1,
        "per_page": 20,
        "companyId": localStorage.getItem("companyId"),// todo userInfo.companyId
      })
          .then(function (response) {
            that.departmentList = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  },
  watch: {
    activeId(newValue, oldValue) {
      alert('oldValue = ' + oldValue + ' newValue = ' + newValue)


    },
    active(newValue, oldValue) {
      //alert('oldValue = ' + oldValue + ' newValue = ' +newValue)
      console.log(newValue)
      console.log(oldValue)
      this.getDepartmentList(this.parentList[newValue].deptId)
    }
  }
  /*setup() {
    const active = ref(0);
    const onChange = (index) => Toast(`标签名 ${index + 1}`);
    return {
      active,
      onChange,
    };
  },*/
}
</script>

<style scoped>
.van-cell__title {
  font-size: medium;
}
</style>